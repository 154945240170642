<template>
  <div>
    <ul>
      <li v-for="(item, i) in predicts" :key="i" class="p-insight-progress">
        <div v-if="item.absa != null">
          <p>
            {{
              item.absa == 0
                ? "Neutral"
                : item.absa == 1
                ? "Positive"
                : item.absa == 2
                ? "Negative"
                : ""
            }}
          </p>
          <el-progress
            :text-inside="true"
            :stroke-width="20"
            :percentage="Math.round((item.total / reviews) * 100)"
            :color="
              item.absa == 0
                ? customColor1
                : item.absa == 1
                ? customColor2
                : item.absa == 2
                ? customColor3
                : '#FFFFFF'
            "
          ></el-progress>
          <span>{{ item.total }}</span>
        </div>
      </li>
    </ul>
    <!-- <div class="p-insight-progress">
      <p>보통</p>
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="series[1]"
        :color="customColor2"
        status="success"
      ></el-progress>
      <span>{{ reviews[1] }}</span>
    </div>
    <div class="p-insight-progress">
      <p>별로예요</p>
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="series[2]"
        :color="customColor3"
        status="warning"
      ></el-progress>
      <span>{{ reviews[2] }}</span>
    </div>-->
  </div>
</template>
<script>
export default {
  props: {
    series: {
      type: Object,
    },
    reviews: {
      type: Number,
    },
  },
  data() {
    return {
      customColor1: "#a4b0f0",
      customColor2: "#b2d76d",
      customColor3: "#f58a28",
      predicts: this.series.predicts.sort((a, b) => {
        return a["absa"] - b["absa"];
      }),
    };
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? "Full" : `${percentage}%`;
    },
  },
};
</script>
